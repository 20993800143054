import React, {useState} from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import {Link} from "react-router-dom";
import {ImBlog} from "react-icons/im";
import {useMediaQuery} from "react-responsive";
import {NavDropdown} from "react-bootstrap";

function NavBar() {
    const isMobile = useMediaQuery({maxWidth: 600});
    const [expand, updateExpanded] = useState(false);
    const [navColour, updateNavbar] = useState(false);
    const [isExpanded, setExpanded] = useState(false);

    const handleLinkClick = () => {
        setExpanded(false);
    };

    function scrollHandler() {
        if (window.scrollY >= 20) {
            updateNavbar(true);
        } else {
            updateNavbar(false);
        }
    }

    window.addEventListener("scroll", scrollHandler);


    return (
        <Navbar
            expanded={expand}
            fixed="top"
            expand="md"
            className={navColour ? "sticky" : "navbar"}
        >
            <Container>
                <Navbar.Brand href="/" className="d-flex">
                    {/*<img src={logo} className="img-fluid logo" alt="brand"/>*/}
                </Navbar.Brand>
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    onClick={() => {
                        updateExpanded(expand ? false : "expanded");
                    }}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto" defaultActiveKey="#home">
                        <Nav.Item>
                            <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                                Home
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <NavDropdown title={<span>About</span>}
                                         id="about-dropdown">
                                <NavDropdown.Item as={Link} to="/about" onClick={() => updateExpanded(false)}>
                                    About Javier
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/current" onClick={() => updateExpanded(false)}>
                                    Currently
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav.Item>


                        <Nav.Item>
                            <NavDropdown title={<span>Culture</span>}
                                         id="books-dropdown">
                                <NavDropdown.Item as={Link} to="/books" onClick={() => updateExpanded(false)}>
                                    Books
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/songs" onClick={() => updateExpanded(false)}>
                                    Music Library
                                </NavDropdown.Item>
                                {!isMobile && (
                                    <NavDropdown.Item as={Link} to="/concerts" onClick={() => updateExpanded(false)}>
                                        Upcoming Concerts
                                    </NavDropdown.Item>
                                )}
                                <NavDropdown.Item as={Link} to="/movies" onClick={() => updateExpanded(false)}>
                                    Movies
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/series" onClick={() => updateExpanded(false)}>
                                    Series
                                </NavDropdown.Item>

                                <NavDropdown.Item as={Link} to="/books-wishlist"
                                                  onClick={() => updateExpanded(false)}>
                                    Books Wishlist
                                </NavDropdown.Item>

                                <NavDropdown.Item as={Link} to="/sets"
                                                  onClick={() => updateExpanded(false)}>
                                    Sets
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link
                                as={Link}
                                to="/courses"
                                onClick={() => updateExpanded(false)}
                            >
                                Courses
                            </Nav.Link>
                        </Nav.Item>


                        <Nav.Item>
                            <Nav.Link
                                as={Link}
                                to="/projects"
                                onClick={() => updateExpanded(false)}
                            >
                                Projects
                            </Nav.Link>
                        </Nav.Item>


                        <Nav.Item>
                            <NavDropdown title={<span>Websites</span>}
                                         id="books-dropdown">
                                <NavDropdown.Item
                                    href="https://finance.mrfreire.net/"
                                    target="_blank" // Set the target attribute to "_blank" to open the link in a new tab
                                    onClick={handleLinkClick}
                                >
                                    Finance
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    href="https://finance.mrfreire.net/"
                                    target="_blank" // Set the target attribute to "_blank" to open the link in a new tab
                                    onClick={handleLinkClick}
                                >

                                    Cycling Market Value
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    href="https://nba.mrfreire.net/"
                                    target="_blank" // Set the target attribute to "_blank" to open the link in a new tab
                                    onClick={handleLinkClick}
                                >

                                    NBA Market Value
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link
                                href="https://shop.mrfreire.net/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Shop
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link
                                href="https://mrfreire.net/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <ImBlog style={{marginBottom: "2px"}}/> Blog
                            </Nav.Link>
                        </Nav.Item>

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavBar;
